import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

export default () => <Layout>
	<Helmet title="Gina McDonald &amp; Associates" defer={false} />

	<Container>
		<Row>
			<Col>Thank you for contacting us.</Col>
		</Row>
	</Container>
</Layout>